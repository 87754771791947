<template>
  <validation-observer v-slot="{ handleSubmit }">
    <b-form ref="bookingPositionForm" @submit="handleSubmit(() => submit($event))" autocomplete="off" novalidate>
      <BookingPositionJobandTalent
        :position="position"
        :booking="booking"
      />
    </b-form>
  </validation-observer>
</template>
<script>
import BookingPositionJobandTalent from './BookingPositionJobandTalent'

export default {
  name: 'BookingPositionJobandTalentCreate',
  components: { BookingPositionJobandTalent },
  props: {
    position: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  methods: {
    submit (ev) {
      const bookingPosition = this.position
      bookingPosition.bookingId = this.booking.id
      delete bookingPosition.handler
      delete bookingPosition.jobandtalent
      bookingPosition.pricePerDay = parseInt(this.position.pricePerDay, 10)
      bookingPosition.annotation = this.position.annotation !== '' ? this.position.annotation : null
      bookingPosition.endDate = bookingPosition.endDate.replace('T', ' ')
      bookingPosition.startDate = bookingPosition.startDate.replace('T', ' ')

      this.$store.dispatch('updateJobandTalentBookingPosition', bookingPosition)
        .then(() => {
          this.$emit('created', ev.detail)
        })
    }
  }
}
</script>
