<template>
  <div>
    <HandlerSelector
      :position="position"
    />
    <b-row>
      <b-col>
        <h6>Título</h6>
        <validation-provider
          vid="title"
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="title" label-for="input-title">
            <b-form-input
              id="title"
              type="text"
              name="title"
              :state="getValidationState(validationContext)"
              placeholder="Escribe título de la posición"
              v-model="position.title"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Fecha</h6>
        <v-date-picker
          v-model="dateRange"
          color="indigo"
          is-dark
          is-range
          is-expanded
          locale="ES-es"
          :popover="{ placement: 'auto' }"
          @input="dateSelected"
          :available-dates='{ start: booking.getEventStartAt, end: booking.getEventEndAt }'
          mode="dateTime"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col>
                <validation-provider
                  vid="startDate"
                  name="fecha de inicio"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="startDate-group" label-for="input-start-date">
                    <b-form-input
                      id="startDate"
                      name="start-date"
                      placeholder="Fecha inicio"
                      :state="getValidationState(validationContext)"
                      readonly
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  vid="endDate"
                  name="fecha de final"
                  rules="required|todayOn"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="endDate-group" label-for="input-end-date">
                    <b-form-input
                      id="endDate"
                      name="end-date"
                      placeholder="Fecha final"
                      readonly
                      :state="getValidationState(validationContext)"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </template>
        </v-date-picker>
        <small class="placeholder font-weight-light d-inline-block mb-3">
          * Solo se pueden seleccionar rango de fechas
        </small>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Precio por jornada</h6>
        <validation-provider
          vid="price"
          name="precio"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="price" label-for="input-price">
            <b-form-input
              id="price"
              type="number"
              name="price"
              :state="getValidationState(validationContext)"
              placeholder="Coste empresa JobandTalent"
              v-model="position.pricePerDay"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div class="jobandtalent-price">
          <span class="jobandtalent-price__text">
            Salario bruto JobandTalent: {{ formatPrice(price(position.getPricePerDay)) }}
          </span>
          <b-icon-question-circle-fill
            class="jobandtalent-price__icon"
            id="jobandtalentPopover"
          />
        </div>
        <b-popover target="jobandtalentPopover" :show.sync="popover">
          <div class="h4" style="text-align: right">
            <b-icon-x style="color: black" @click="popover = !popover" />
          </div>
          <p>
            Cuando publicas una posición con la opción de contratar con
            JobandTalent te olvidas de la contratación, dejando esta función
            para Jobandtalent.
          </p>
          <p>¿Qué ventajas tiene para tu empresa?</p>
          <ul>
            <li>
              Te olvidas de todo, Jobandtalent hace todo el trámite por ti.
              Ahorras en coste de personal, puesto que gracias a la app de
              booking de Avify y los servicios de Jobandtalent tú solo eliges
              a quien quieres tener en tu próximo evento y del resto nos
              encargamos nosotros con un ahorro considerable de personal de
              recursos humanos y gastos de gestoría que tendrías que hacer de
              otra manera.
            </li>
            <li>
              Olvídate de los PRL puesto que ahora es responsabilidad de
              Jobandtalent.
            </li>
            <li>
              Tu personal contratado en 3 clicks. El/los candidatos elegidos
              reciben en su móvil el contrato que firman en el acto
              digitalmente desde su móvil.
            </li>
            <li>
              Gestión de contratos y trámites, confección de contratos, pago de
              nóminas, alta y baja en la SS, indemnizaciones, IRPF y cualquier
              incidencia que pueda surgir. Jobandtalent se encarga de todo.
            </li>
            <li>
              Financiación de nóminas, los empleados recibirán su salario
              siempre a tiempo, y tendrás hasta 30 días para abonar la
              factura correspondiente.
            </li>
            <li>
              Total transparencia en el proceso de contratación y control de
              las horas trabajadas por el empleado.
            </li>
            <li>
              Sustitución ilimitada. Podrás sustituir al trabajador en
              cualquier momento si no estais contentos con su rendimiento.
              Si el reemplazo es en las primeras 48h, Jobandtalent cubre el
              salario de las horas ya trabajadas.
            </li>
            <li>
              La combinación de Avify Booking y la plataforma de
              contratación de Jobandtalent se convierte en una herramienta
              imprescindible para las empresas de eventos y espectáculos.
              Olvídate de los procesos de contratación, nosotros lo hacemos
              por ti.
            </li>
          </ul>
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Datos del técnico</h6>
        <validation-provider
          vid="annotation"
          name="datos del técnico"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="annotation" label-for="input-annotation">
            <b-form-textarea
              id="annotation"
              name="annotation"
              v-model="position.annotation"
              :state="getValidationState(validationContext)"
              placeholder="Escribe aquí el nombre y los datos de contacto de la persona que deseas contratar y nosotros haremos el resto"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import { HANDLER_NATIVE, HANDLER_WORKOUT } from '@/domain/models/BookingPosition'
import { formatPrice } from '@/domain/services/FormatPrice'
import { price } from '@/domain/services/JobandTalentPrice'
import HandlerSelector from './HandlerSelector.vue'
import { BIconQuestionCircleFill, BIconX } from 'bootstrap-vue'

export default {
  name: 'BookingPositionJobandTalent',
  components: { HandlerSelector, BIconQuestionCircleFill, BIconX },
  props: {
    position: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      price,
      formatPrice,
      freelanceOptions: [
        { value: true, text: 'Sí' },
        { value: false, text: 'No' }
      ],
      handlerOptions: [
        { value: HANDLER_NATIVE, text: 'No' },
        { value: HANDLER_WORKOUT, text: 'Sí' }
      ],
      dateRange: {
        start: this.position.getStartDate ? this.position.getStartDate : null,
        end: this.position.getEndDate ? this.position.getEndDate : null
      },
      popover: false
    }
  },
  methods: {
    dateSelected (date) {
      this.position.startDate = this.formatDate(date.start)
      this.position.endDate = this.formatDate(date.end)
    },
    formatDate (date) {
      return date.getFullYear() + '-' +
        this.lpad(date.getMonth() + 1) + '-' +
        this.lpad(date.getDate()) + ' ' +
        this.lpad(date.getHours()) + ':' +
        this.lpad(date.getMinutes()) + ':' +
        this.lpad(date.getSeconds())
    },
    lpad (value) {
      return String(value).padStart(2, '0')
    }
  }
}
</script>
<style lang="less">
@import "../../style/text";
@import "../../style/responsive";
  div.modal-dialog {
    .modal-body {
      padding: 15px;
      div.handler-selection {
        div.row {
          &:last-of-type {
            border-bottom: 1px solid #31395A;
            margin-bottom: 1rem;
          }
          div.col.title {
            font-weight: bold;
          }
        }
      }
    }
  }
  .popover {
    max-width: 70%;
    max-height: 400px;
    overflow: auto;
    @media (max-width: @break_xs) {
      max-width: 100%;
      max-height: 100%;
      transform: translate3d(4px, 4px, 0px) !important;
    }
    .arrow {
      display: none;
    }
  }
  form {
    h6 {
      font-family: @font_fira;
    }
    .jobandtalent-price {
      &__text {
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        vertical-align: sub;
      }
      &__icon {
        color: #c4c4c4;
        font-size: 11px;
      }
    }
    #freelance {
      margin-bottom: 0;
      .custom-control {
        display: inline-flex;
        align-items: center;
        label {
          padding: 2px;
        }
      }
      > div {
        display: inline-flex;
        font-size: 0.75rem;
        > div {
          margin-right: 2rem;
        }
      }
    }
    .placeholder {
      font-size: 12px;
    }
  }
</style>
