<template>
  <div class="handler-selection">
    <b-row>
      <b-col class="title">
        ¿Conoces al técnico que quieres contratar y quieres tramitar su
        contratación a través de Workout?
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      <b-form-group id="handler" label-for="input-handler">
        <b-form-radio-group
          class="avify-radio filter"
          v-model="position.hiredByAvify"
          :options="handlerOptions"
          name="handler"
        ></b-form-radio-group>
      </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint vue/no-mutating-props: 0 */

export default {
  name: 'HandlerSelector',
  props: {
    position: {
      type: Object
    }
  },
  data () {
    return {
      handlerOptions: [
        { value: true, text: 'No' },
        { value: false, text: 'Sí' }
      ]
    }
  }
}
</script>

<style lang="less">
  div.modal-dialog {
    .modal-body {
      padding: 15px;
      div.handler-selection {
        div.row {
          &:last-of-type {
            border-bottom: 1px solid #31395A;
            margin-bottom: 1rem;
          }
          div.col.title {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
