export default class EditableBooking {
  constructor (bookingData) {
    this.id = bookingData.id
    this.name = bookingData.name
    this.opened = bookingData.opened
    this.createdBy = bookingData.createdBy
    this.code = bookingData.code
    this.location = bookingData.location
    this.eventStartAt = bookingData.eventStartAt
    this.eventEndAt = bookingData.eventEndAt
    this.eventType = bookingData.eventType
    this.catering = bookingData.catering
    this.docs = bookingData.docs
    this.docExtra = bookingData.docExtra
    this.positions = bookingData.positions
  }

  get getId () {
    return this.id
  }

  get getOpened () {
    return this.opened
  }

  updateOpened (opened) {
    this.opened = opened
  }

  get getName () {
    return this.name
  }

  get getCode () {
    return this.code
  }

  get getCreatedBy () {
    return this.createdBy
  }

  get getEventType () {
    return this.eventType
  }

  get getLocation () {
    return this.location
  }

  get getEventStartAt () {
    return new Date(this.eventStartAt)
  }

  get getEventEndAt () {
    return new Date(this.eventEndAt)
  }

  get getCatering () {
    return this.catering
  }

  get getDocs () {
    return this.docs
  }

  get getDocExtra () {
    return this.docExtra
  }

  get getPositions () {
    return this.positions
  }

  get hasPositions () {
    return this.positions.length > 0
  }
}
