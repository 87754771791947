<template>
  <bookings
    :displayedCard="displayedCard"
  >
    <b-card
      align="left"
      class="view-booking side-card colored"
      v-if="booking"
    >
      <b-row>
        <b-col><h4>{{ booking.getName }}</h4></b-col>
        <b-col
          class="text-right"
          v-if="booking !== null && booking.getOpened && !booking.getBroadcasted"
        >
          <span class="edit-booking" v-b-modal.booking-modal>
            <img :src="require('@/assets/icons/edit_card.svg')">
          </span>
          <modal-component
            id="booking-modal"
            title="Editar booking"
            ok-title="Editar"
            size="lg"
            @confirmed="onEditBooking"
          >
            <add-booking-info
              ref="editBooking"
              :booking="booking"
            />
          </modal-component>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" lg="3">
          <TitleValue title="ID del booking" :value="booking.getCode"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Creado por" :value="booking.getCreatedBy"/>
        </b-col>
        <div class="d-block-sm w-100" />
        <b-col cols="6" lg="3">
          <TitleValue title="Fecha de creación" :value="getDate(booking.getCreatedAt)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Estado" :value="getBookingStatus(booking)"/>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      align="left"
      class="view-booking side-card colored"
      v-if="booking"
    >
      <b-row>
        <b-col><h4>Detalles del evento</h4></b-col>
        <b-col
          class="text-right"
          v-if="booking !== null && booking.getOpened && !booking.getBroadcasted"
        >
          <span class="edit-booking" v-b-modal.booking-modal>
            <img :src="require('@/assets/icons/edit_card.svg')">
          </span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" lg="3">
          <TitleValue title="Fecha del evento" :value="getEventDates(booking)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Hora del evento" :value="getEventHours(booking)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Lugar del evento" :value="getLocation(booking.getLocation)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Tipo de evento" :value="booking.getEventType.name"/>
        </b-col>
      </b-row>
      <b-row class="start" v-if="booking.getDocExtra || booking.getDocs.length > 0">
        <b-col>
          Documentos
        </b-col>
      </b-row>
      <b-row class="end">
        <b-col>
          <SkillBadge
            v-for="doc in booking.getDocs"
            :key="doc.id"
            :name="doc.name"
          />
          <SkillBadge
            :name="booking.getDocExtra"
            v-if="booking.getDocExtra"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      align="left"
      class="view-booking side-card positions"
      v-if="booking"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col cols="9" sm="7" md="6" ><h4>Posiciones del booking</h4></b-col>
          <b-col cols="3" sm="5" md="6" v-if="booking && !booking.getBroadcasted">
            <b-button
              v-b-modal.position-modal
              class="float-right d-inline-block p-0 d-sm-none"
              variant="outline">
              <img :src="require('@/assets/icons/create-icon.svg')">
            </b-button>
            <b-button
              v-if="!booking.hasPositions && booking.getOpened"
              v-b-modal.position-modal
              class="float-right d-none d-sm-inline-block"
              variant="primary">
              <img :src="require('@/assets/icons/create-icon.svg')">
              Crear posición
            </b-button>
            <b-button
              v-if="booking.hasPositions && booking.getOpened"
              v-b-modal.position-modal
              class="float-right d-none d-sm-inline-block p-0"
              variant="outline">
              <img :src="require('@/assets/icons/create-icon.svg')">
            </b-button>
            <modal-component
              id="position-modal"
              title="Nueva posición"
              ok-title="Añadir"
              deleteText="Añadir y crear otra"
              deleteButton
              size="lg"
              @confirmed="onCreatePosition"
              @deleted="onCreatePositionAndAdd"
            >
              <BookingPositionModal
                ref="bookingPositionModal"
                :position="bookingPosition"
                :booking="booking"
                @created="positionCreated"
              >
              </BookingPositionModal>
            </modal-component>
          </b-col>
        </b-row>
        <b-row class="start" v-if="!booking.hasPositions">
          <b-col>
            <div><strong>¡Vaya!</strong></div>
            Todavía no hay posiciones añadidas al booking
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="booking.hasPositions" class="position-list">
        <b-container>
          <b-row
            v-for="bookingPosition in booking.positions"
            :key="bookingPosition.getId"
            class="booking-position-item"
          >
            <b-col>
              <component :is="getViewBookingPositionComponent(bookingPosition.getNamedHandler)"
                :booking="booking"
                :bookingPosition="bookingPosition"
                :broadcasted="booking.getBroadcasted"
                @updateBooking="setBooking"
                :editable="booking !== null && booking.getOpened && !booking.getBroadcasted"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-row class="button-wrapper">
      <b-col>
        <b-button
          v-if="canBePublished"
          v-b-modal.broadcast-booking
          class="float-right broadcast-booking"
          variant="primary">
            Publicar booking
        </b-button>
        <b-modal
          id="broadcast-booking"
          modal-class="user-information-modal booking-modals centered"
          centered
          hide-header
          hide-footer
        >
          <b-row>
            <b-col>
              <h4>¿Publicar y difundir el Booking?</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Los técnicos que se ajusten a tus posiciones recibirán
              una notificación para aplicar al Booking.
            </b-col>
          </b-row>
          <b-row class="buttons">
            <b-col>
              <b-button class="float-right" variant="secondary" @click="onDiscardBooking">Descartar</b-button>
            </b-col>
            <b-col>
              <b-button class="float-left" variant="primary" @click="onBroadcastBooking">Publicar</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="broadcast-booking-with-jobandtalent"
          modal-class="user-information-modal booking-modals centered"
          centered
          hide-header
          hide-footer
        >
          <b-row>
            <b-col>
              <h2>¡Gestionamos tus contrataciones!</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Si quieres que JobandTalent se encargue de toda la gestión de la contratación de tus
              posiciones para este booking indícanoslo a continuación.
            </b-col>
          </b-row>
          <b-row>
            <b-col class="position-table">
              <b-row>
                <b-col class="pl-4">
                  <h4>Posiciones de tu booking</h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="pr-0 pl-0">
                  <b-form @submit.stop.prevent="onBroadcastBooking" autocomplete="off" novalidate v-if="booking">
                    <table>
                      <tr>
                        <td />
                        <td class="pl-4">Título</td>
                        <td class="d-none d-sm-table-cell">Roles</td>
                        <td class="d-none d-sm-table-cell">Autónomo</td>
                        <td class="d-none d-sm-table-cell">Precio</td>
                        <td class="pr-4">JobandTalent</td>
                      </tr>
                      <tr v-for="position in booking.getPositions" :key="position.getId">
                        <td class="pl-4"><img src="../assets/icons/empty_avatar.svg"></td>
                        <td>{{ position.getTitle }}</td>
                        <td class="d-none d-sm-table-cell role">{{ position.getRoles.map(role => role.name).join(", ") }}</td>
                        <td class="d-none d-sm-table-cell">
                          <span v-if="position.getHandler == HANDLER_NATIVE">
                            {{ position.getFreelance ? "Sí" : "No" }}
                          </span>
                          <span v-else>
                            -
                          </span>
                        </td>
                        <td class="d-none d-sm-table-cell">
                          {{ formatPrice(position.getPrice) }}
                          <div class="jobandtalentNotice" v-if="position.getHandler === HANDLER_JOBANDTALENT || bookingPositionJobAndTalent.indexOf(position.getId) >= 0">
                            Coste de empresa {{ formatPrice(jobandTalentPrice(position.getPrice)) }}
                          </div>
                        </td>
                        <td>
                          <b-form-checkbox
                            v-if="position.getHandler !== HANDLER_JOBANDTALENT"
                            v-model="bookingPositionJobAndTalent"
                            :value="position.getId"
                          />
                          <b-form-checkbox
                            v-else
                            disabled
                            checked
                            value
                          />
                        </td>
                      </tr>
                    </table>
                  </b-form>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="buttons">
            <b-col>
              <b-button class="float-right" variant="secondary" @click="onDiscardBooking">Descartar</b-button>
            </b-col>
            <b-col>
              <b-button class="float-left" variant="primary" @click="onBroadcastBooking">Publicar</b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-button
          v-if="booking !== null && booking.getBroadcasted && booking.getOpened"
          @click="$bvModal.show('close-booking')"
          class="float-right broadcast-booking"
          variant="primary">
            Cerrar Booking
        </b-button>
        <b-modal
          id="close-booking"
          modal-class="user-information-modal booking-modals centered"
          centered
          size="sm"
          hide-header
          hide-footer
        >
          <b-row>
            <b-col>
              <h4>¿Cerrar el Booking?</h4>
            </b-col>
          </b-row>
          <b-row class="buttons">
            <b-col>
              <b-button class="float-right" variant="secondary" @click="onDiscardClosingBooking">Descartar</b-button>
            </b-col>
            <b-col>
              <b-button class="float-left" variant="primary" @click="onCloseBooking">Cerrar</b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
  </bookings>
</template>

<script>
import Bookings from '@/views/Bookings.vue'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingPositionModal from '@/components/BookingPosition/BookingPositionModal.vue'
import ViewBookingPositionNative from '@/components/BookingPosition/ViewBookingPositionNative.vue'
import ViewBookingPositionJobandTalent from '@/components/BookingPosition/ViewBookingPositionJobandTalent.vue'
import EditableBookingFactory from '@/domain/services/EditableBookingFactory'
import SkillBadge from '@/components/SkillBadge/SkillBadge'
import AddBookingInfo from '@/components/Bookings/AddBookingInfo.vue'
import TitleValue from '@/components/Bookings/TitleValue.vue'
import { formatPrice } from '@/domain/services/FormatPrice'
import { price as jobandTalentPrice } from '@/domain/services/JobandTalentPrice'
import { HANDLER_NATIVE, HANDLER_WORKOUT } from '@/domain/models/BookingPosition'

export default {
  name: 'Booking',
  data: function () {
    return {
      booking: null,
      bookingPositionJobAndTalent: [],
      bookingPosition: {
        bookingId: null,
        roles: [],
        skills: [],
        drivingLicenses: [],
        pricePerDay: null,
        freelance: null,
        title: null,
        annotation: null,
        startDate: null,
        endDate: null,
        handler: HANDLER_NATIVE
      },
      formatPrice,
      jobandTalentPrice,
      HANDLER_NATIVE,
      HANDLER_JOBANDTALENT: HANDLER_WORKOUT
    }
  },
  components: {
    Bookings,
    ModalComponent,
    BookingPositionModal,
    ViewBookingPositionNative,
    ViewBookingPositionJobandTalent,
    SkillBadge,
    AddBookingInfo,
    TitleValue
  },
  created: async function () {
    await this.setBooking()
  },
  computed: {
    displayedCard () {
      if (!this.booking) {
        return null
      }

      if (this.booking.getEventEndAt.getTime() <= (new Date()).getTime()) {
        return 'expired'
      }
      if (this.booking.getOpened) {
        return 'opened'
      }

      return 'closed'
    },
    canBePublished () {
      return this.booking !== null &&
        this.booking.getOpened &&
        !this.booking.getBroadcasted &&
        this.booking.positions.length > 0
    }
  },
  methods: {
    async setBooking () {
      this.booking = await this.$store.dispatch('getBookingById', this.$route.params.id)
      this.bookingPosition.bookingId = this.booking.getId
    },
    getBookingStatus (booking) {
      if (booking.getOpened) {
        return 'Abierto'
      }
      return 'Cerrado'
    },
    getDate (date) {
      return this.lpad(date.getDate()) + '/' +
        this.lpad((date.getMonth() + 1)) + '/' +
        date.getFullYear()
    },
    lpad (data) {
      return String(data).padStart(2, '0')
    },
    getLocation (location) {
      return location.name + ' (' + location.administrative + ')'
    },
    getEventDates (booking) {
      const start = booking.getEventStartAt
      const end = booking.getEventEndAt
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        return this.getShortDates(start, end)
      }
      return this.getLongDates(start, end)
    },
    getShortDates (start, end) {
      return this.lpad(start.getDate()) + ' - ' +
        this.lpad(end.getDate()) + ' de ' +
        this.getMonth(start.getMonth()) + ' ' +
        start.getFullYear()
    },
    getLongDates (start, end) {
      return this.lpad(start.getDate()) + '/' +
        this.lpad(start.getMonth() + 1) + '/' +
        start.getFullYear() + ' al ' +
        this.lpad(end.getDate()) + '/' +
        this.lpad(end.getMonth() + 1) + '/' +
        end.getFullYear()
    },
    getMonth (jsMonth) {
      return [
        'enero',
        'febrero',
        'marzo',
        'abil',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ][jsMonth]
    },
    getEventHours (booking) {
      const start = booking.getEventStartAt
      const end = booking.getEventEndAt
      return this.lpad(start.getHours()) + ':' + this.lpad(start.getMinutes()) +
        ' - ' +
        this.lpad(end.getHours()) + ':' + this.lpad(end.getMinutes())
    },
    positionCreated (data) {
      this.resetBookingPositionForm()
      this.setBooking()
      this.$bvModal.hide(data.name)
      if (!data.skipSuccessDialog) {
        this.$store.dispatch('showConfirmationModal')
      } else {
        this.$bvModal.show(data.name)
      }
    },
    onCreatePosition (name, skipSuccessDialog) {
      this
        .$refs
        .bookingPositionModal
        .$refs
        .formContainer
        .$refs
        .bookingPositionForm.dispatchEvent(new CustomEvent(
          'submit',
          {
            detail: {
              name: name,
              skipSuccessDialog: skipSuccessDialog
            }
          }
        ))
    },
    onCreatePositionAndAdd (name) {
      this.onCreatePosition(name, true)
    },
    resetBookingPositionForm () {
      this.bookingPosition = {
        bookingId: null,
        roles: [],
        skills: [],
        drivingLicenses: [],
        quantity: null,
        freelance: null,
        title: null,
        annotation: null,
        startDate: null,
        endDate: null,
        handler: HANDLER_NATIVE
      }
    },
    onEditBooking () {
      this.$refs.editBooking.$refs.addBookingInfoForm.validate()
        .then(valid => {
          if (!valid) {
            return
          }

          delete this.booking.broadcasted
          delete this.booking.createdAt

          this.lockScreen()

          this.$store.dispatch('updateBooking', this.booking)
            .then((booking) => {
              this.setBooking()
                .then(() => {
                  this.$bvModal.hide('booking-modal')
                  this.$store.dispatch('showConfirmationModal')
                    .then(() => {
                      this.unlockScreen()
                    })
                })
            })
        })
    },
    onBroadcastBooking () {
      const data = {
        booking: this.booking,
        positions: this.bookingPositionJobAndTalent
      }
      this.$store.dispatch('broadcastBooking', data)
        .then(() => {
          this.setBooking()
          this.$bvModal.hide('broadcast-booking')
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          this.$bvModal.hide('broadcast-booking')
          console.log(error)
        })
    },
    onDiscardBooking () {
      this.$bvModal.hide('broadcast-booking')
    },
    onCloseBooking () {
      const editableBooking = EditableBookingFactory.getEditableBooking(this.booking)
      editableBooking.updateOpened(false)
      this.$store.dispatch('updateBooking', editableBooking)
        .then(() => {
          this.setBooking()
          this.$bvModal.hide('close-booking')
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          this.$bvModal.hide('close-booking')
          console.log(error)
        })
    },
    onDiscardClosingBooking () {
      this.$bvModal.hide('close-booking')
    },
    getViewBookingPositionComponent (handlerName) {
      return 'ViewBookingPosition' + handlerName
    }
  }
}
</script>

<style lang="less">
  @import "../style/text";
  .modal-dialog {
    max-width: 700px;
    .modal-body {
      padding: 2.5rem 2rem 1.5rem;
      input[type=checkbox]:disabled + label::before {
        background-color: gray;
      }
    }
  }
</style>
<style lang="less" scoped>
  @import 'viewBooking.less';
  @import "../style/text";

  .edit-booking {
    cursor: pointer;
  }
  .booking-position-item {
    padding: 1rem 15px;
    border-bottom: 1px solid @color_dark_purple;
    &:last-of-type {
      border-bottom-width: 0;
    }
  }
  .modal-dialog {
    font-family: @font_fira;
    header {
      padding: 0.85rem 2rem;
    }
    .modal-body {
      padding: 2.5rem 2rem 1.5rem;
      .jobandtalentNotice {
        font-size: 0.80rem;
        font-weight: normal;
        font-style: italic;
      }
    }
    footer.modal-footer {
      padding: 1.5rem 2rem 2rem;
      border: 1px solid @color_dark_purple !important;
    }
  }
</style>
