<template>
  <div>
    <BookingPositionNativeUpdate
      ref="formContainer"
      v-if="positionCopy.handler == HANDLER_NATIVE"
      :position="positionCopy"
      :booking="booking"
      @created="$emit('created', $event)"
    />
    <BookingPositionJobandTalentUpdate
      ref="formContainer"
      v-if="positionCopy.handler == HANDLER_JOBANDTALENT"
      :position="positionCopy"
      :booking="booking"
      @created="$emit('created', $event)"
    />
  </div>
</template>
<script>
import BookingPositionNativeUpdate from './BookingPositionNativeUpdate'
import BookingPositionJobandTalentUpdate from './BookingPositionJobandTalentUpdate'
import BookingPositionFactory from '@/domain/services/BookingPositionFactory'
import { HANDLER_NATIVE, HANDLER_WORKOUT } from '@/domain/models/BookingPosition'

export default {
  name: 'BookingPositionModalUpdate',
  components: {
    BookingPositionNativeUpdate,
    BookingPositionJobandTalentUpdate
  },
  props: {
    position: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      HANDLER_NATIVE,
      HANDLER_JOBANDTALENT: HANDLER_WORKOUT,
      positionCopy: BookingPositionFactory.getBookingPosition(this.position)
    }
  }
}
</script>
