<template>
  <div>
    <HandlerSelector
      v-if="false"
      :position="position"
    />
    <b-row>
      <b-col>
        <h6>Título</h6>
        <validation-provider
          vid="title"
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="title" label-for="input-title">
            <b-form-input
              id="title"
              type="text"
              name="title"
              :state="getValidationState(validationContext)"
              placeholder="Escribe título de la posición"
              v-model="position.title"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Fecha</h6>
        <v-date-picker
          v-model="dateRange"
          color="indigo"
          is-dark
          is-range
          is-expanded
          locale="ES-es"
          :popover="{ placement: 'auto' }"
          @input="dateSelected"
          :available-dates='{ start: booking.getEventStartAt, end: booking.getEventEndAt }'
          mode="dateTime"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col>
                <validation-provider
                  vid="startDate"
                  name="fecha de inicio"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="startDate-group" label-for="input-start-date">
                    <b-form-input
                      id="startDate"
                      name="start-date"
                      placeholder="Fecha inicio"
                      :state="getValidationState(validationContext)"
                      readonly
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  vid="endDate"
                  name="fecha de final"
                  rules="required|todayOn"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="endDate-group" label-for="input-end-date">
                    <b-form-input
                      id="endDate"
                      name="end-date"
                      placeholder="Fecha final"
                      readonly
                      :state="getValidationState(validationContext)"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </template>
        </v-date-picker>
        <small class="placeholder font-weight-light d-inline-block mb-3">
          * Solo se pueden seleccionar rango de fechas
        </small>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ProfessionalSkillSelect
          title="Roles"
          placeholder="Selecciona uno o varios roles"
          :options="roleOptions"
          :suggestions="true"
          suggestionItem="rol"
          eventName="removeRole"
          :selectedOptions="position.roles"
          @onRemoveRole="onRemoveRole"
          addEventName="addNewRole"
          @addNewRole="onAddRole"
          :are-options-loaded="roleOptions.length === 0"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ProfessionalSkillSelect
          title="Habilidades"
          placeholder="Selecciona una o varias habilidades"
          :options="skillOptions"
          :suggestions="true"
          suggestionItem="habilidad"
          eventName="removeSkill"
          :selectedOptions="position.skills"
          @onRemoveSkill="onRemoveSkill"
          addEventName="addNewSkill"
          @addNewSkill="onAddSkill"
          :are-options-loaded="skillOptions.length === 0"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ProfessionalSkillSelect
          title="Licencias de conducir"
          placeholder="Selecciona una o varias licencias de conducir"
          :options="drivingLicenseOptions"
          :suggestions="true"
          suggestionItem="licencia de conducir"
          eventName="removeDrivingLicense"
          :selectedOptions="position.drivingLicenses"
          @onRemoveDrivingLicense="onRemoveDrivingLicense"
          addEventName="addNewDrivingLicense"
          @addNewDrivingLicense="onAddDrivingLicense"
          :are-options-loaded="drivingLicenseOptions.length === 0"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Precio por jornada</h6>
        <validation-provider
          vid="price"
          name="precio"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="price" label-for="input-price">
            <b-form-input
              id="price"
              type="number"
              name="price"
              :state="getValidationState(validationContext)"
              placeholder="Coste de empresa por jornada"
              v-model="position.pricePerDay"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <h6>Autónomo</h6>
        <b-form-group id="freelance" label-for="input-freelance">
          <b-form-radio-group
            class="avify-radio filter"
            v-model="position.freelance"
            :options="freelanceOptions"
            name="freelance"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Anotaciones</h6>
        <validation-provider
          vid="annotation"
          name="annotation"
          :rules="{ required: false }"
          v-slot="validationContext"
        >
          <b-form-group id="annotation" label-for="input-annotation">
            <b-form-textarea
              id="annotation"
              name="annotation"
              v-model="position.annotation"
              :state="getValidationState(validationContext)"
              placeholder="Escribe anotaciones extras (Opcional)"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect'
import { HANDLER_NATIVE, HANDLER_WORKOUT } from '@/domain/models/BookingPosition'
import HandlerSelector from './HandlerSelector.vue'

export default {
  name: 'BookingPositionNative',
  components: { ProfessionalSkillSelect, HandlerSelector },
  props: {
    position: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  data () {
    const dateRange = {
      start: this.position.getStartDate ? this.position.getStartDate : null,
      end: this.position.getEndDate ? this.position.getEndDate : null
    }
    return {
      freelanceOptions: [
        { value: null, text: 'Indiferente' },
        { value: true, text: 'Sí' },
        { value: false, text: 'No' }
      ],
      handlerOptions: [
        { value: HANDLER_NATIVE, text: 'No' },
        { value: HANDLER_WORKOUT, text: 'Sí' }
      ],
      dateRange: dateRange
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    roleOptions () {
      return this.$store.getters.getRolesList
    },
    skillOptions () {
      return this.$store.getters.getSkillsList
    },
    drivingLicenseOptions () {
      return this.$store.getters.getDrivingLicensesList
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('getRoles')
      this.$store.dispatch('getSkills')
      this.$store.dispatch('getDrivingLicenses')
    },
    onAddRole (role) {
      const exists = this.position.roles.findIndex(
        item => item.id === role.id
      )
      if (exists === -1) {
        this.position.roles.push(role)
      }
    },
    onRemoveRole (id) {
      this.position.roles.forEach((role, index) => {
        if (role.id === id) {
          this.position.roles.splice(index, 1)
        }
      })
    },
    onAddSkill (skill) {
      const exists = this.position.skills.findIndex(
        item => item.id === skill.id
      )
      if (exists === -1) {
        this.position.skills.push(skill)
      }
    },
    onRemoveSkill (id) {
      this.position.skills.forEach((skill, index) => {
        if (skill.id === id) {
          this.position.skills.splice(index, 1)
        }
      })
    },
    onAddDrivingLicense (drivingLicense) {
      const exists = this.position.drivingLicenses.findIndex(
        item => item.id === drivingLicense.id
      )
      if (exists === -1) {
        this.position.drivingLicenses.push(drivingLicense)
      }
    },
    onRemoveDrivingLicense (id) {
      this.position.drivingLicenses.forEach((drivingLicense, index) => {
        if (drivingLicense.id === id) {
          this.position.drivingLicenses.splice(index, 1)
        }
      })
    },
    dateSelected (date) {
      this.position.startDate = this.formatDate(date.start)
      this.position.endDate = this.formatDate(date.end)
    },
    formatDate (date) {
      return date.getFullYear() + '-' +
        this.lpad(date.getMonth() + 1) + '-' +
        this.lpad(date.getDate()) + ' ' +
        this.lpad(date.getHours()) + ':' +
        this.lpad(date.getMinutes()) + ':' +
        this.lpad(date.getSeconds())
    },
    lpad (value) {
      return String(value).padStart(2, '0')
    }
  }
}
</script>
<style lang="less">
@import "../../style/text";
  div.modal-dialog {
    .modal-body {
      padding: 15px;
      div.handler-selection {
        div.row {
          &:last-of-type {
            border-bottom: 1px solid #31395A;
            margin-bottom: 1rem;
          }
          div.col.title {
            font-weight: bold;
          }
        }
      }
    }
  }
  form {
    h6 {
      font-family: @font_fira;
    }
    #freelance {
      .custom-control {
        display: inline-flex;
        align-items: center;
        label {
          padding: 2px;
        }
      }
      > div {
        display: inline-flex;
        font-size: 0.75rem;
        > div {
          margin-right: 2rem;
        }
      }
    }
    .placeholder {
      font-size: 12px;
    }
  }
</style>
