<template>
  <b-row>
    <b-col>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column line-space">
          <h6 class="font-weight-bold d-flex align-items-center">
            <span class="mr-2">{{ bookingPosition.title }}</span>
            <AvifyIcon class="ml-2" />
            <JobantTalentIcon class="ml-2" v-if="bookingPosition.getJobandtalent" />
          </h6>
          <span class="text">
            Fecha:
            <span class="pink font-weight-bold">{{ bookingPosition.getEventDate }}</span>
          </span>
          <span class="text">
            Hora:
            <span class="pink font-weight-bold">{{ bookingPosition.getOnlyTime }}</span>
          </span>
          <span class="text">
            Roles:
            <span class="pink font-weight-bold" v-if="roles">{{ roles }}</span>
            <span class="pink font-weight-bold" v-else>No requerido</span>
          </span>

          <span class="text">
            Habilidades:
            <span class="pink font-weight-bold" v-if="skills">{{ skills }}</span>
            <span class="pink font-weight-bold" v-else>No requeridas</span>
          </span>

          <span class="text">
            Licencias:
            <span class="pink font-weight-bold" v-if="licenses">{{ licenses }}</span>
            <span class="pink font-weight-bold" v-else>No requeridas</span>
          </span>

          <span class="text">
            Precio:
            <span class="pink font-weight-bold">
              {{ formatPrice(bookingPosition.getPricePerDay) }}
            </span>
          </span>

          <span class="text" v-if="bookingPosition.annotation">
            Anotaciones:
            <span class="pink font-weight-bold">{{ bookingPosition.annotation }}</span>
          </span>
        </div>
        <div>
          <b-button
            v-b-modal="bookingPosition.id"
            class="btn float-right btn-outline"
            v-if="editable"
          >
            Editar
          </b-button>
          <ModalComponent
            :id="bookingPosition.id"
            title="Editar posición"
            ok-title="Guardar"
            size="lg"
            @confirmed="onEditPosition"
          >
            <BookingPositionModalUpdate
              ref="bookingPositionModal"
              :position="bookingPosition"
              :booking="booking"
              @created="updated"
            />
          </ModalComponent>
        </div>
      </div>
    </b-col>
    <b-col cols="5" v-if="broadcasted">
      <div class="position-side">
        <div v-if="bookingPosition.getOpened" class="opened-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición abierta</span>
          <img
            alt="Posición abierta"
            :src="require('@/assets/icons/booking-opened.svg')"
          >
        </div>
        <div v-if="!bookingPosition.getOpened" class="closed-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición cerrada</span>
          <img
            alt="Posición cerrada"
            :src="require('@/assets/icons/booking-closed.svg')"
          >
        </div>
        <div class="contacted-technicians-technicians" v-on:click="goToPosition">
          <span>{{ totalContactedTechnicians }}</span>
          <img
            :src="require('@/assets/icons/inbox.svg')"
          >
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingPositionModalUpdate from '@/components/BookingPosition/BookingPositionModalUpdate.vue'
import { formatPrice } from '@/domain/services/FormatPrice'
import AvifyIcon from '@/components/SmallLogo/Avify'
import JobantTalentIcon from '@/components/SmallLogo/JobandTalent'

export default {
  name: 'ViewBookingPositionNative',
  components: {
    ModalComponent,
    BookingPositionModalUpdate,
    AvifyIcon,
    JobantTalentIcon
  },
  data () {
    return {
      totalContactedTechnicians: 0,
      formatPrice
    }
  },
  props: {
    bookingPosition: {
      type: Object,
      require: true
    },
    booking: {
      type: Object,
      require: true
    },
    broadcasted: {
      type: Boolean,
      require: true
    },
    editable: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  mounted: async function () {
    const data = {
      bookingId: this.booking.getId,
      bookingPositionId: this.bookingPosition.getId
    }
    this.totalContactedTechnicians = await this
      .$store
      .dispatch('totalContactedTechnicianFromBusiness', data)
  },
  computed: {
    roles () {
      return this.joinArrayNames(this.bookingPosition.getRoles)
    },
    skills () {
      return this.joinArrayNames(this.bookingPosition.getSkills)
    },
    licenses () {
      return this.joinArrayNames(this.bookingPosition.getDrivingLicenses)
    }
  },
  methods: {
    joinArrayNames (array) {
      return array.map(item => { return item.name }).join(', ')
    },
    updated (data) {
      this.$emit('updateBooking')
      this.$bvModal.hide(data.name)
      this.$store.dispatch('showConfirmationModal')
    },
    onEditPosition (name) {
      this
        .$refs
        .bookingPositionModal
        .$refs
        .formContainer
        .$refs
        .bookingPositionForm.dispatchEvent(new CustomEvent(
          'submit',
          {
            detail: {
              name: name,
              skipSuccessDialog: false
            }
          }
        ))
    },
    goToPosition () {
      this.$router.push({
        name: 'view-position-list',
        params: {
          id: this.booking.getId,
          positionId: this.bookingPosition.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  @import '../../style/colors.less';
  @import 'BookingPosition.less';

  .line-space {
    line-height: 24px;
  }

  .contacted-technicians-technicians {
    cursor: pointer;
  }

  .position-side {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    text-align: right;
    font-size: 12px;
    img {
      width: 16px;
      vertical-align: middle;
      margin-left: 5px;
    }
    .opened-position {
      color: @color_radiant_pink;
      img {
        filter: invert(44%) sepia(42%) saturate(3903%) hue-rotate(317deg) brightness(101%) contrast(101%);
      }
    }
    span {
      display: inline-block;
      background-color: @color_dark_blue_light;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
</style>
