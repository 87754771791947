import EditableBooking from '../models/EditableBooking'

class EditableBookingFactory {
  getEditableBooking (data) {
    const user = {
      id: data.id,
      name: data.name,
      opened: data.opened,
      createdBy: data.createdBy,
      code: data.code,
      location: data.location,
      eventStartAt: data.eventStartAt,
      eventEndAt: data.eventEndAt,
      eventType: data.eventType,
      catering: data.catering,
      docs: data.docs,
      docExtra: data.docExtra ? data.docExtra.name : null,
      positions: data.positions
    }
    return new EditableBooking(user)
  }
}

export default new EditableBookingFactory()
