<template>
  <validation-observer v-slot="{ handleSubmit }">
    <b-form ref="bookingPositionForm" @submit="handleSubmit(() => submit($event))" autocomplete="off" novalidate>
      <BookingPositionNative
        :position="position"
        :booking="booking"
      />
    </b-form>
  </validation-observer>
</template>
<script>
import BookingPositionNative from './BookingPositionNative'

export default {
  name: 'BookingPositionNativeCreate',
  components: { BookingPositionNative },
  props: {
    position: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  methods: {
    submit (ev) {
      const bookingPosition = this.position
      bookingPosition.bookingId = this.booking.id
      delete bookingPosition.handler
      bookingPosition.pricePerDay = parseInt(this.position.pricePerDay, 10)
      bookingPosition.annotation = this.position.annotation !== '' ? this.position.annotation : null

      this.$store.dispatch('createBookingPosition', bookingPosition)
        .then(() => {
          this.$emit('created', ev.detail)
        })
    }
  }
}
</script>
